interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    classes?: string
    withArrow: boolean
}

const Arrow = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4297 1.92993L18.4997 7.99993L12.4297 14.0699" stroke="#131313" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.5 8H18.33" stroke="#131313" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const AccentButton: React.FC<ButtonProps> = ({text, classes, withArrow, ...props}) => {
    return (
        <button className={`bg-accent hover:bg-yellow-500 transition duration-300 text-black cursor-pointer border-0 px-10 py-4 font-[700] text-[16px] rounded-full text-normal ${classes}`} {...props}>
            {text}
            {withArrow && <Arrow />}
        </button>
    )
}

export default AccentButton;