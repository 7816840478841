import React, { useEffect, useReducer, useState } from 'react';
import logoMini from '../assets/logoMini.png';
import ShopBag from '../assets/shopBag';
import PL from '../assets/poland.png';
import CZ from '../assets/czechy.png';
import AU from '../assets/austria.png';
import SZ from '../assets/szwajcaria.png';
import DE from '../assets/germany.png';
import EN from '../assets/english.png';
import WE from "../assets/wegry.png";
import RU from "../assets/rumunia.png";
import SLOV from '../assets/Slovakia.png';
import BUL from '../assets/bulgaria.png';
import SLO from '../assets/slovenia.png';
import Arrow from '@/assets/Arrow';
import Switcher from '@/assets/Switcher';
import Logged from '@/assets/Logged';
import { getImg, logout } from '@/utils/dataStorage';
import RegionalPreferencesModal from './RegionalPreferencesModal';
import router, { useRouter } from 'next/router';
import CartFull from '@/assets/CartFull';
import BtnDarkMode from './DarkModeSwapper';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


const Navbar: React.FC = () => {
  const [currency, setCurrency] = useState('pl');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [winietyDropdownOpen, setWinietyDropdownOpen] = useState(false);
  const [winietyMobileDropdownOpen, setWinietyMobileDropdownOpen] = useState(false);
  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const [loginMobileDropdownOpen, setLoginMobileDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cart, setCart] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const [imgPath, setImgPath] = useState<string | null>(null);
  const fullPath = imgPath ? `https://api.winiety24.eu/${imgPath}` : null;

  const changeLanguage = (lang: any) => {
    setCurrency(lang);
    i18n.changeLanguage(lang);
    if (typeof window !== 'undefined') {
      localStorage.setItem('i18nextLng', lang); // Zapisz język w localStorage
    }
  };

  useEffect(() => {
    const cartData = localStorage.getItem('cart');
    setCart(cartData ? JSON.parse(cartData) : undefined);
    if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
      setImgPath(getImg());
      if(localStorage.getItem('i18nextLng') != null)
      {
        let lang = localStorage.getItem('i18nextLng');
        setCurrency(lang ? lang : 'pl');
      }
    } 
    setIsInitialized(true);
  }, []);


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleWinietyDropdown = () => {
    setWinietyDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setWinietyDropdownOpen(false);
  };

  const closeLoginDropdown = () => {
    setLoginDropdownOpen(false);
  };

  const closeMobileDropdown = () => {
    setIsMobileMenuOpen(false);
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const accountData = localStorage.getItem('token');
    setIsLoggedIn(!!accountData);
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Sprawdź, czy kliknięto poza dropdownem lub jego aktywatorem
      const dropdown = document.getElementById('winiety-dropdown');
      const activator = document.getElementById('winiety-activator');
      
      if (
        dropdown &&
        activator &&
        !dropdown.contains(event.target as Node) &&
        !activator.contains(event.target as Node)
      ) {
        closeDropdown(); // Zamknij dropdown
      }
      
    };
  
    // Dodanie event listenera
    document.addEventListener('mousedown', handleOutsideClick);
  
    // Czyszczenie event listenera
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Sprawdź, czy kliknięto poza dropdownem lub jego aktywatorem
      const dropdown = document.getElementById('login-dropdown');
      const activator = document.getElementById('login-activator');
      
      if (
        dropdown &&
        activator &&
        !dropdown.contains(event.target as Node) &&
        !activator.contains(event.target as Node)
      ) {
        closeLoginDropdown(); // Zamknij dropdown
      }
      
    };
  
    // Dodanie event listenera
    document.addEventListener('mousedown', handleOutsideClick);
  
    // Czyszczenie event listenera
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Sprawdź, czy kliknięto poza dropdownem lub jego aktywatorem
      const dropdown = document.getElementById('mobile-dropdown');
      const activator = document.getElementById('mobile-activator');
      
      if (
        dropdown &&
        activator &&
        !dropdown.contains(event.target as Node) &&
        !activator.contains(event.target as Node)
      ) {
        closeMobileDropdown(); // Zamknij dropdown
      }
      
    };
  
    // Dodanie event listenera
    document.addEventListener('mousedown', handleOutsideClick);
  
    // Czyszczenie event listenera
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const getImageSrc = () => {
    switch (currency) {
      case 'pl':
        return PL.src;
      case 'en':
        return EN.src;
      case 'de':
        return DE.src;
      default:
        return PL.src;
    }
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <header className="sticky top-0 left-0 z-50 bg-primary text-base-100">
      <div className="max-w-screen-2xl mx-auto flex items-center justify-between px-4 lg:px-6 py-4">
        <div className="flex items-center justify-between w-full lg:w-auto">
          <a href="/">
            <img src={logoMini.src} alt="Prospect Group Logo" className="w-36" />
          </a>
          <div className="flex lg:hidden items-center justify-center space-x-6">
            <span className='cursor-pointer'><Switcher /></span>
            <div className="flex items-center space-x-2">
              <img src={getImageSrc()} onClick={() => setIsModalOpen(true)} alt="Country Flag" className="h-6 w-6" />
            </div>
            <div className='flex items-center' onClick={()=> router.push('/cart')}>
              {cart === undefined ? (
                <div className='max-h-[22px]'>
                  <ShopBag className='fill-base-100'/>
                </div>
              ) : (
                <div className='max-h-[52px]'>
                  <CartFull className='fill-base-100'/>
                </div>
              )}
              {/* <ShopBag /> */}
            </div>
          </div>
          <div
            className="lg:hidden flex flex-col items-center justify-center w-8 h-8 bg-[#FEFA17] rounded-full cursor-pointer"
            onClick={toggleMobileMenu}
            id='mobile-activator'
          >
            <span className="block w-4 h-0.5 bg-black mb-1"></span>
            <span className="block w-4 h-0.5 bg-black mb-1"></span>
            <span className="block w-4 h-0.5 bg-black"></span>
          </div>
        </div>

        <nav className="hidden lg:flex flex-1 justify-center">
          <div className="flex flex-wrap justify-center space-x-1 lg:space-x-3 xl:space-x-4 2xl:space-x-6 text-[14px] xl:text-[16px] font-medium">
            <a href="/" className="hover:text-accent text-base-100 no-underline">
            {t('menu.home')}
            </a>
            <a href="/#popular" className="hover:text-accent text-base-100 no-underline">
            {t('menu.popularCountries')}
            </a>
            <a href="/#advantages" className="hover:text-accent text-base-100 no-underline">
            {t('menu.advantages')}
            </a>
            <div className="relative">
              {/* Aktywator dropdownu */}
              <button
                onClick={() => setWinietyDropdownOpen((prev) => !prev)}
                id='winiety-activator'
                className="flex items-center space-x-2 border-0 bg-transparent text-normal text-base-100 hover:text-accent cursor-pointer"
              >
                <span>{t('menu.availableVignettes')}</span> <div className={`transition duration-300 ${winietyDropdownOpen ? "rotate-180" : "rotate-0"}`}><Arrow className='stroke-base-100'/></div>
              </button>

              {/* Dropdown */}
              {winietyDropdownOpen && (
                <div
                  id="winiety-dropdown"
                  className="absolute top-12 left-0 py-3 mt-2 w-40 bg-primary text-base-100 rounded-2xl border border-solid border-secondary shadow-lg z-10"
                >
                  <a
                    href="/popularne-kraje/austria"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                  >
                    <img src={AU.src} alt='Flaga Austrii' className='w-6'/>{t('menu.austria')}
                  </a>
                  <a
                    href="/popularne-kraje/czechy"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={CZ.src} alt='Flaga Austrii' className='w-6'/>{t('menu.czechy')}
                  </a>
                  <a
                    href="/popularne-kraje/szwajcaria"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={SZ.src} alt='Flaga Szwajcarii' className='w-6'/>{t('menu.szwajcaria')}
                  </a>
                  <a
                    href="/popularne-kraje/wegry"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={WE.src} alt='Flaga Węgier' className='w-6'/>{t('menu.wegry')}
                  </a>
                  <a
                    href="/popularne-kraje/rumunia"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={RU.src} alt='Flaga Rumunii' className='w-6'/>{t('menu.rumunia')}
                  </a>
                  <a
                    href="/popularne-kraje/slowacja"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={SLOV.src} alt='Flaga Słowacjii' className='w-6'/>{t('menu.slowacja')}
                  </a>
                  <a
                    href="/popularne-kraje/bulgaria"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={BUL.src} alt='Flaga Bułgarii' className='w-6'/>{t('menu.bulgaria')}
                  </a>
                  <a
                    href="/popularne-kraje/slowenia"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={SLO.src} alt='Flaga Słowenii' className='w-6'/>{t('menu.slowenia')}
                  </a>
                </div>
              )}
            </div>
            <a href="/blog" className="hover:text-accent text-base-100 no-underline">
            {t('menu.blog')}
            </a>
            <a href="/faq" className="hover:text-accent text-base-100 no-underline">
            {t('menu.faq')}
            </a>
            <a href="/contact" className="hover:text-accent text-base-100 no-underline">
            {t('menu.contact')}
            </a>
          </div>
        </nav>

        <div className="hidden lg:flex items-center space-x-2 lg:space-x-4">
          <div className="flex items-center space-x-2 2xl:space-x-8">
            {/* <div className='w-3 flex justify-center items-center h-3 xl:w-6 xl:h-6 border-2 border-solid border-base-100 p-3 rounded-full'> */}
                {/* <div className='max-h-[22px]'>
                <label className='swap swap-rotate'>
                <input type='checkbox' className='theme-controller' value='customLight' />
                    <Switcher className='fill-base-100'/>
                </label>
                </div> */}
                {/* <BtnDarkMode /> */}
            {/* </div> */}
            <img src={getImageSrc()} alt="Country Flag" className="h-4 w-4 cursor-pointer" onClick={() => setIsModalOpen(true)}/>
          </div>
          <div className="flex items-center space-x-2">
            {isLoggedIn ? (
              <div className="relative bg-transparent">
                <button
                  onClick={toggleDropdown}
                  className="flex items-center text-sm lg:text-base font-bold bg-transparent border-0 focus:outline-none"
                >
                    {typeof window === 'undefined' || imgPath === '' ? (
                      <div
                      className={`min-h-12 min-w-12 max-h-12 max-w-12 flex flex-col justify-center items-center bg-secondary border-[1px] border-base-100 border-solid rounded-full overflow-hidden uppercase`}
                      >
                        <div className="text-center text-[22px] text-base-100">{localStorage.getItem('username') ? localStorage.getItem('username')?.slice(0, 1) : ''}</div>
                      </div>
                    ) : (
                      <div
                      className={`min-h-12 min-w-12 max-h-12 max-w-12 flex flex-col justify-center items-center rounded-full overflow-hidden uppercase`}
                      >
                        <div>
                            <img
                                src={fullPath ? fullPath : ''}
                                alt="Profile"
                                className="min-h-12 min-w-12 max-w-12 rounded-full"
                            />
                        </div>
                      </div>
                    )}
                  <span className="ml-3 mr-2 cursor-pointer text-base-100 text-[16px]">{localStorage.getItem('username')}</span>
                  <Arrow className='stroke-base-100'/>
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 rounded bg-secondary">
                    <a href='/home' className='no-underline block w-full text-left px-4 py-2 bg-transparent cursor-pointer border-0 text-base-100 hover:text-accent'>{t('menu.mojekonto')}</a>
                    <button
                      className="block w-full text-left px-4 py-2 bg-transparent cursor-pointer border-0 text-[16px] text-base-100 hover:text-accent"
                      onClick={logout}
                    >
                      {t('menu.wyloguj')}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                {/* <a
                  href="/login"
                  className="flex no-underline items-center text-sm lg:text-base text-black font-bold bg-[#FEFA17] px-2 xl:px-6 py-3 border-0 border-solid border-[#FEFA17] rounded-full hover:bg-yellow-400 hover:border-yellow-400 transition"
                >
                  Zaloguj się
                </a>
                <a
                  href="/register"
                  className="flex no-underline items-center text-sm lg:text-base text-black font-bold bg-accent border-[#FEFA17] border border-solid px-2 xl:px-6 py-3 rounded-full hover:bg-yellow-400 hover:border-yellow-400 hover:text-black transition"
                >
                  Zarejestruj się
                </a> */}
                <div className="relative">
              {/* Aktywator dropdownu */}
              <button
                onClick={() => setLoginDropdownOpen((prev) => !prev)}
                id='login-activator'
                className="flex no-underline items-center text-sm lg:text-base cursor-pointer text-black font-bold bg-[#FEFA17] px-2 xl:px-6 py-3 border-0 border-solid border-[#FEFA17] rounded-full hover:bg-yellow-400 hover:border-yellow-400 transition mx-4"
              >
                <span className='pr-4'>{t('menu.login')}</span> <div className={`transition duration-300 ${loginDropdownOpen ? "rotate-180" : "rotate-0"}`}><Arrow className='stroke-black'/></div>
              </button>

              {/* Dropdown */}
              {loginDropdownOpen && (
                <div
                  id='login-dropdown'
                  className="absolute top-12 left-0 py-3 mt-2 w-40 bg-accent text-base-100 rounded-2xl border border-solid border-secondary shadow-lg z-10"
                >
                  <a
                    href="/login"
                    className="px-4 py-2 text-black flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                  >
                    {t('menu.login1')}
                  </a>
                  <a
                    href="/register"
                    className="px-4 py-2 text-black flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    {t('menu.register')}
                  </a>
                </div>
              )}
            </div>
              </>
            )}
            <div className='w-3 h-3 xl:w-6 xl:h-6 p-3 flex justify-center hover:bg-gray-600 cursor-pointer items-center bg-secondary border-base-100 border border-solid rounded-full' onClick={()=> router.push('/cart') }>
              {cart === undefined ? (
                <div className='max-h-[22px]'>
                  <ShopBag className='fill-base-100'/>
                </div>
              ) : (
                <div className='max-h-[52px]'>
                  <CartFull className='fill-base-100'/>
                </div>
              )}
                  {/* <ShopBag className='fill-base-100'/> */}
            </div>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && (
        <nav className="lg:hidden bg-[#131313] text-white" id='mobile-dropdown'>
          <div className="flex flex-col items-center space-y-4 py-6">
            <a href="/" className="hover:text-yellow-500 text-white no-underline">
            {t('menu.home')}
            </a>
            <a href="/#popular" className="hover:text-yellow-500 text-white no-underline" onClick={()=>setIsMobileMenuOpen(false)}>
            {t('menu.popularCountries')}
            </a>
            <a href="/#advantages" className="hover:text-yellow-500 text-white no-underline" onClick={()=>setIsMobileMenuOpen(false)}>
            {t('menu.advantages')}
            </a>
            <div className="relative">
              {/* Aktywator dropdownu */}
              <button
                onClick={() => setWinietyMobileDropdownOpen((prev) => !prev)}
                id='winiety-mobile-activator'
                className="flex items-center w-full text-center space-x-2 border-0 bg-transparent text-normal text-white hover:text-yellow-500"
              >
                <span>{t('menu.availableVignettes')}</span> <div className={`transition duration-300 ${winietyMobileDropdownOpen ? "rotate-180" : "rotate-0"}`}><Arrow /></div>
              </button>

              {/* Dropdown */}
              {winietyMobileDropdownOpen && (
                <div
                  id="winiety-mobile-dropdown"
                  className="py-3 mt-2 w-40 bg-primary text-base-100 rounded-2xl border border-solid border-secondary shadow-lg z-10"
                >
                  <a
                    href="/popularne-kraje/austria"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                  >
                    <img src={AU.src} alt='Flaga Austrii' className='w-6'/>{t('menu.austria')}
                  </a>
                  <a
                    href="/popularne-kraje/czechy"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={CZ.src} alt='Flaga Austrii' className='w-6'/>{t('menu.czechy')}
                  </a>
                  <a
                    href="/popularne-kraje/szwajcaria"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={SZ.src} alt='Flaga Szwajcarii' className='w-6'/>{t('menu.szwajcaria')}
                  </a>
                  <a
                    href="/popularne-kraje/wegry"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={WE.src} alt='Flaga Węgier' className='w-6'/>{t('menu.wegry')}
                  </a>
                  <a
                    href="/popularne-kraje/rumunia"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={RU.src} alt='Flaga Rumunii' className='w-6'/>{t('menu.rumunia')}
                  </a>
                  <a
                    href="/popularne-kraje/slowacja"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={SLOV.src} alt='Flaga Słowacji' className='w-6'/>{t('menu.slowacja')}
                  </a>
                  <a
                    href="/popularne-kraje/bulgaria"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={BUL.src} alt='Flaga Bułgarii' className='w-6'/>{t('menu.bulgaria')}
                  </a>
                  <a
                    href="/popularne-kraje/slowenia"
                    className="px-4 py-2 text-base-100 flex items-center gap-4 hover:bg-secondary rounded-2xl hover:text-yellow-500 no-underline"
                    >
                    <img src={SLO.src} alt='Flaga Słowenii' className='w-6'/>{t('menu.slowenia')}
                  </a>
                </div>
              )}
            </div>
            <a href="/blog" className="hover:text-yellow-500 text-white no-underline">
            {t('menu.blog')}
            </a>
            <a href="/faq" className="hover:text-yellow-500 text-white no-underline">
            {t('menu.faq')}
            </a>
            <a href="/contact" className="hover:text-yellow-500 text-white no-underline">
            {t('menu.contact')}
            </a>
          </div>
          <div className="flex items-center justify-center pb-6">
            {isLoggedIn ? (
              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className="flex items-center text-base font-bold bg-transparent text-[#FEFA17] border-0 focus:outline-none"
                >
                  <div
                    className={`min-h-12 min-w-12 max-h-12 max-w-12 flex flex-col justify-center items-center bg-secondary border-[1px] border-neutral border-solid rounded-full overflow-hidden uppercase`}
                  >
                    {typeof window === 'undefined' || imgPath === '' ? (
                      <div className="text-center text-[22px] text-base-100">{localStorage.getItem('username') ? localStorage.getItem('username')?.slice(0, 1) : ''}</div>
                    ) : (
                        <div>
                            <img
                                src={fullPath ? fullPath : ''}
                                alt="Profile"
                                className="min-h-12 min-w-12 max-h-12 max-w-12 rounded-full"
                            />
                        </div>
                    )}
                  </div>
                  <span className="ml-3 mr-2 cursor-pointer text-white text-[16px]">{localStorage.getItem('username')}</span>
                  <Arrow />
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 rounded bg-[#131313]">
                    <a href='/home' className='no-underline block w-full text-left px-4 py-2 bg-transparent cursor-pointer border-0 text-white hover:text-yellow-500'>Moje konto</a>
                    <button
                      className="block w-full text-left px-4 py-2 text-white border-0 bg-transparent text-[16px] hover:text-yellow-500"
                      onClick={logout}
                    >
                      Wyloguj
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <a
                  href="/login"
                  className="flex no-underline items-center text-base font-bold text-[#FEFA17] px-4 py-2 border border-[#FEFA17] rounded-full hover:text-yellow-400 hover:border-yellow-400 transition"
                >
                  {t('menu.login')}
                </a>
                <a
                  href="/register"
                  className="flex no-underline items-center text-base font-bold text-black px-4 py-2 border bg-accent border-[#FEFA17] rounded-full hover:text-yellow-400 hover:border-yellow-400 transition"
                >
                  {t('menu.register')}
                </a>
              </>
            )}
          </div>
        </nav>
      )}
      <RegionalPreferencesModal onSbmt={changeLanguage} isOpen={isModalOpen}  onClose={() => setIsModalOpen(false)}/>
    </header>
  );
};

export default Navbar;
