import * as React from "react";

interface IconProps {
  className?: string
}

const SvgIcon: React.FC<IconProps> = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    fill="none"
    viewBox="0 0 52 52"
    className={className}
  >
    <path
      className={className}
      d="M29.438 22.223v-1.265c0-.962-.413-1.897-1.128-2.548-.724-.66-1.66-.962-2.65-.87-1.65.155-3.097 1.842-3.097 3.602v.889a.693.693 0 0 1-.688.687.693.693 0 0 1-.687-.687v-.898c0-2.466 1.989-4.74 4.345-4.969 1.374-.128 2.694.303 3.703 1.229a4.83 4.83 0 0 1 1.576 3.565v1.265a.693.693 0 0 1-.687.688.693.693 0 0 1-.687-.688"
    ></path>
    <path
      className={className}
      fillRule="evenodd"
      d="M23.25 35.854c-4.235 0-5.023-1.97-5.225-3.886l-.688-5.491c-.1-.99-.137-2.411.825-3.474.825-.917 2.191-1.357 4.171-1.357h7.334c1.989 0 3.355.449 4.17 1.357.954 1.063.926 2.484.825 3.455l-.687 5.51c-.202 1.915-.99 3.886-5.225 3.886zm-4.07-11.926c-.45.495-.596 1.256-.477 2.393l.688 5.49c.156 1.467.559 2.677 3.859 2.677h5.5c3.3 0 3.703-1.2 3.859-2.658l.688-5.51c.119-1.117-.028-1.878-.477-2.383-.541-.614-1.604-.916-3.154-.916h-7.333c-1.549 0-2.612.302-3.153.907"
      clipRule="evenodd"
    ></path>
    <path
      className={className}
      d="M28.21 26.137a.92.92 0 0 1 .916-.916.92.92 0 0 1 .917.916.91.91 0 0 1-.908.917.92.92 0 0 1-.926-.917M21.793 26.137a.92.92 0 0 1 .916-.916.92.92 0 0 1 .917.916.91.91 0 0 1-.908.917.92.92 0 0 1-.925-.917"
    ></path>
    <circle cx="34" cy="19" r="5" fill="#FEFA17"></circle>
  </svg>
);

export default SvgIcon;
