interface IconProps {
  className?: string
}

const SvgIcon: React.FC<IconProps> = ({className}) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4375 7.22344V5.95844C14.4375 4.99594 14.025 4.06094 13.31 3.41011C12.5858 2.75011 11.6508 2.44761 10.6608 2.53928C9.01083 2.69511 7.5625 4.38178 7.5625 6.14178V7.03094C7.5625 7.40678 7.25083 7.71844 6.875 7.71844C6.49917 7.71844 6.1875 7.40678 6.1875 7.03094V6.13261C6.1875 3.66678 8.17667 1.39344 10.5325 1.16428C11.9075 1.03594 13.2275 1.46678 14.2358 2.39261C15.235 3.30011 15.8125 4.60178 15.8125 5.95844V7.22344C15.8125 7.59928 15.5008 7.91094 15.125 7.91094C14.7492 7.91094 14.4375 7.59928 14.4375 7.22344Z" className={className}/>
<path fillRule="evenodd" clipRule="evenodd" d="M8.24983 20.8543C4.01483 20.8543 3.2265 18.8835 3.02483 16.9677L2.33733 11.4768C2.2365 10.4868 2.19983 9.066 3.16233 8.00266C3.98733 7.086 5.35317 6.646 7.33317 6.646H14.6665C16.6557 6.646 18.0215 7.09516 18.8373 8.00266C19.7907 9.066 19.7632 10.4868 19.6623 11.4585L18.9748 16.9677C18.7732 18.8835 17.9848 20.8543 13.7498 20.8543H8.24983ZM4.17983 8.9285C3.73067 9.4235 3.584 10.1843 3.70317 11.321L4.39067 16.8118C4.5465 18.2785 4.94983 19.4885 8.24983 19.4885H13.7498C17.0498 19.4885 17.4532 18.2877 17.609 16.8302L18.2965 11.321C18.4157 10.2027 18.269 9.44183 17.8198 8.93766C17.279 8.3235 16.2157 8.021 14.6665 8.021H7.33317C5.784 8.021 4.72067 8.3235 4.17983 8.9285Z" className={className}/>
<path d="M13.2093 11.1374C13.2093 10.6332 13.6218 10.2207 14.126 10.2207C14.6301 10.2207 15.0426 10.6332 15.0426 11.1374C15.0426 11.6415 14.6393 12.054 14.1351 12.054C13.6218 12.054 13.2093 11.6415 13.2093 11.1374Z" className={className}/>
<path d="M6.79266 11.1374C6.79266 10.6332 7.20516 10.2207 7.70933 10.2207C8.2135 10.2207 8.626 10.6332 8.626 11.1374C8.626 11.6415 8.22266 12.054 7.7185 12.054C7.20516 12.054 6.79266 11.6415 6.79266 11.1374Z" className={className}/>
</svg>

);

export default SvgIcon;
