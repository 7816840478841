interface IconProps {
  className?: string
}
const SvgIcon: React.FC<IconProps> = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    fill="none"
    viewBox="0 0 11 6"
  >
    <path className={className} d="m1 1 3.086 3.086a2 2 0 0 0 2.828 0L10 1"></path>
  </svg>
);

export default SvgIcon;
