interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    classes?: string,
    withArrow: boolean,
    withBackArrow?: boolean,
    textClass?: string
}

const Arrow = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4297 1.92993L18.4997 7.99993L12.4297 14.0699" stroke="#838086" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.5 8H18.33" stroke="#838086" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const GrayButton: React.FC<ButtonProps> = ({text, classes, withArrow, withBackArrow, textClass, ...props}) => {
    return (
        <button  
        className={`flex items-center text-[16px] bg-transparent text-neutral px-10 py-4 rounded-full border border-solid border-[#131313] hover:text-gray-400 hover:border-gray-600 cursor-pointer transition ${classes}`}
        {...props}
        >
        {withBackArrow && <div className="rotate-180 flex items-center"><Arrow /></div>}
        <p className={`${textClass} p-0 m-0`}>{text}</p>
        {withArrow && <Arrow />}
        </button>
    )
}

export default GrayButton;