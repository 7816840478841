interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    children?: React.ReactNode; // Dodanie typu dla children
  }
  
  const SelectField: React.FC<SelectFieldProps> = ({ children, ...props }) => {
    return (
      <select
          className="w-full num p-5 box-border text-sm bg-primary text-base-100 rounded-[51px] border-2 border-solid border-[#242226] placeholder-base-200 focus:outline-none focus:border-gray-600"
        {...props}
        >
        {children}
      </select>
    );
  };
  
  export default SelectField;